import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import { Default } from "./Default";
import { Unpolished } from "./Unpolished";

function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/unpolished">
                    <Unpolished />
                </Route>
                <Route path="/" exact>
                    <Default />
                </Route>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
