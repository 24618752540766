import { useState } from "react";
import React from "react";
import Helmet from "react-helmet";

type Step = 1 | 2 | 3 | 4 | 5 | 6 | undefined;

type Result =
    | "allCorona"
    | "singleCorona"
    | "friendsCorona"
    | "noCorona"
    | "inkakkenIsBijpakken";

interface State {
    step: Step | Result;
}

const initialState: State = {
    step: undefined
};

interface QuestionProps {
    update: (_: Step | Result) => void;
}

const QuestionOne = (props: QuestionProps) => {
    const date = new Date().getDay();
    const currentDay =
        date === 0
            ? "zondag"
            : date === 1
            ? "maandag"
            : date === 2
            ? "dinsdag"
            : date === 3
            ? "woensdag"
            : date === 4
            ? "donderdag"
            : date === 5
            ? "vrijdag"
            : "zaterdag";
    return (
        <Container>
            <h2>{`Het is inmiddels ${currentDay}. Hoe voel je je?`}</h2>
            <button onClick={() => props.update(2)}>
                <i className="fas fa-skull-crossbones"></i> Tering slecht
            </button>
            <button onClick={() => props.update(2)}>Gematigd</button>
            <button onClick={() => props.update("noCorona")}>
                Kan nie beter
            </button>
        </Container>
    );
};

const QuestionTwo = (props: QuestionProps) => (
    <Container>
        <h2>Had je een weekender?</h2>
        <button onClick={() => props.update(3)}>Ja</button>
        <button onClick={() => props.update("singleCorona")}>Nee</button>
    </Container>
);

const QuestionThree = (props: QuestionProps) => (
    <Container>
        <h2>Vrijdag tot half 10 gebleven????</h2>
        <button onClick={() => props.update(4)}>Uiteraard</button>
        <button onClick={() => props.update("inkakkenIsBijpakken")}>
            Nee, was moe
        </button>
    </Container>
);

const QuestionFour = (props: QuestionProps) => (
    <Container>
        <h2>Hoe hard heb je staan hakken?</h2>
        <button onClick={() => props.update("friendsCorona")}>
            Tot ik er bij neerviel
        </button>
        <button onClick={() => props.update("allCorona")}>
            Kon nie meer lopen
        </button>
    </Container>
);

const Saturday = (props: QuestionProps) => (
    <Container>
        <h2>Sowieso wat gemist. Hoe hard ging je?</h2>
        <button onClick={() => props.update(6)}>Hard genoeg</button>
        <button onClick={() => props.update("inkakkenIsBijpakken")}>
            Niet
        </button>
    </Container>
);

const People = (props: QuestionProps) => (
    <Container>
        <h2>Met hoeveel vrienden was je?</h2>
        <button onClick={() => props.update("allCorona")}>Zeker 300</button>
        <button onClick={() => props.update("allCorona")}>The usual</button>
        <button onClick={() => props.update("allCorona")}>
            Iedereen is m'n vriend
        </button>
    </Container>
);

const InKakkenIsBijPakken = (props: QuestionProps) => (
    <Container>
        <h2>Inkakken is...</h2>
        <button onClick={() => props.update(1)}>
            Nog maar een keer testen dan
        </button>
    </Container>
);

const EveryoneCorona = (props: QuestionProps) => (
    <Video>
        <h2>R.I.P. iedereen</h2>
        <button onClick={() => props.update(1)}>We gaan allemaal naar de corona</button>
    </Video>
);

const NoCorona = (props: QuestionProps) => (
    <Container>
        <h2>Geen corona dus, de ballen</h2>
        <button onClick={() => props.update(1)}>
            Nee wil 't toch zeker weten
        </button>
    </Container>
);

const FriendsCorona = (props: QuestionProps) => (
    <Video>
        <h2>Allemaal in quarantaine</h2>
        <button onClick={() => props.update(1)}>Ja hoor</button>
    </Video>
);

interface Children {
    children: React.ReactNode;
}

const Video = (props: Children) => (
    <>
        <div className="fullscreen-bg">
            <video loop autoPlay className="fullscreen-bg__video">
                <source src="/unpolished/video.webm" type="video/webm" />
                <source src="/unpolished/video.mp4" type="video/mp4" />
                <source src="/unpolished/video.ogv" type="video/ogg" />
            </video>
        </div>
        {props.children}
        <div className="whodis">
            <span>low effort app by a</span>{" "}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://instagram.com/ambrkhs"
            >
                raver
            </a>
        </div>
    </>
);

const Container = (props: Children) => (
    <div className="container">
        <div className="background"></div>
        <div className="content">{props.children}</div>
        <div className="whodis">
            <span>low effort app by a</span>{" "}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://instagram.com/ambrkhs"
            >
                raver
            </a>
        </div>
    </div>
);

const Start = (props: QuestionProps) => {
    return (
        <div className="container">
            <div className="content">
                <div className="image">
                    <img src="https://festivalfans.nl/wp-content/uploads/2014/06/Unpolished-2020.jpg" />
                </div>
                <h1>Corona Zelftest Unpolished Editie</h1>
                <button onClick={() => props.update(1)}>Start</button>
            </div>
        </div>
    );
};
export const Unpolished = () => {
    const [state, setState] = useState(initialState);
    return state.step === 1 ? (
        <QuestionOne update={wizard => setState({ ...state, step: wizard })} />
    ) : state.step === 2 ? (
        <QuestionTwo update={wizard => setState({ ...state, step: wizard })} />
    ) : state.step === 3 ? (
        <QuestionThree
            update={wizard => setState({ ...state, step: wizard })}
        />
    ) : state.step === 4 ? (
        <QuestionFour update={wizard => setState({ ...state, step: wizard })} />
    ) : state.step === 6 ? (
        <People update={wizard => setState({ ...state, step: wizard })} />
    ) : state.step === "inkakkenIsBijpakken" ? (
        <InKakkenIsBijPakken
            update={wizard => setState({ ...state, step: wizard })}
        />
    ) : state.step === "singleCorona" ? (
        <Saturday update={wizard => setState({ ...state, step: wizard })} />
    ) : state.step === "allCorona" ? (
        <EveryoneCorona
            update={wizard => setState({ ...state, step: wizard })}
        />
    ) : state.step === "friendsCorona" ? (
        <FriendsCorona
            update={wizard => setState({ ...state, step: wizard })}
        />
    ) : state.step === "noCorona" ? (
        <NoCorona update={wizard => setState({ ...state, step: wizard })} />
    ) : (
        <Start update={wizard => setState({ ...state, step: wizard })} />
    );
};
