import { useState } from "react";
import React from "react";

type Step = 1 | 2 | 3 | 4 | 5;

type Result =
    | "allCorona"
    | "singleCorona"
    | "friendsCorona"
    | "noCorona"
    | "inkakkenIsBijpakken";

interface State {
    step: Step | Result;
}

const initialState: State = {
    step: 1
};

interface QuestionProps {
    update: (_: Step | Result) => void;
}

const QuestionOne = (props: QuestionProps) => (
    <Container>
        <h2>Hoe voel je je?</h2>
        <button onClick={() => props.update(2)}>
            <i className="fas fa-skull-crossbones"></i> Tering slecht
        </button>
        <button onClick={() => props.update(2)}>Gematigd slecht</button>
        <button onClick={() => props.update("noCorona")}>Prima</button>
    </Container>
);

const QuestionTwo = (props: QuestionProps) => (
    <Container>
        <h2>Naar de getver gegaan afgelopen weekend?</h2>
        <button onClick={() => props.update(3)}>Ja</button>
        <button onClick={() => props.update("singleCorona")}>Nee</button>
    </Container>
);

const QuestionThree = (props: QuestionProps) => (
    <Container>
        <h2>Wat had je allemaal op?</h2>
        <button onClick={() => props.update(4)}>Genoeg</button>
        <button onClick={() => props.update("inkakkenIsBijpakken")}>
            Niet genoeg
        </button>
    </Container>
);

const QuestionFour = (props: QuestionProps) => (
    <Container>
        <h2>Met hoeveel mensen heb je geknuffeld?</h2>
        <button onClick={() => props.update("allCorona")}>Minstens 300</button>
        <button onClick={() => props.update("friendsCorona")}>
            Alleen m'n matties
        </button>
        <button onClick={() => props.update("singleCorona")}>Niemand</button>
    </Container>
);

const SingleCorona = (props: QuestionProps) => (
    <Container>
        <h2>Saai man. Maar je hebt vast corona.</h2>
        <button onClick={() => props.update(1)}>
            Nog maar een keer testen dan
        </button>
    </Container>
);

const InKakkenIsBijPakken = (props: QuestionProps) => (
    <Container>
        <h2>Inkakken is bijpakken</h2>
        <button onClick={() => props.update(1)}>
            Nog maar een keer testen dan
        </button>
    </Container>
);

const EveryoneCorona = (props: QuestionProps) => (
    <Video>
        <h2>R.I.P. iedereen</h2>
        <button onClick={() => props.update(1)}>We gaan allemaal naar de corona</button>
    </Video>
);

const NoCorona = (props: QuestionProps) => (
    <Container>
        <h2>Geen corona dus, de ballen</h2>
        <button onClick={() => props.update(1)}>
            Nee wil 't toch zeker weten
        </button>
    </Container>
);

const FriendsCorona = (props: QuestionProps) => (
    <Video>
        <h2>Gezellig met z'n allen in quarantaine.</h2>
        <button onClick={() => props.update(1)}>Please no</button>
    </Video>
);

interface Children {
    children: React.ReactNode;
}

const Video = (props: Children) => (
    <>
        <div className="fullscreen-bg">
            <video loop autoPlay className="fullscreen-bg__video">
                <source src="video.webm" type="video/webm" />
                <source src="video.mp4" type="video/mp4" />
                <source src="video.ogv" type="video/ogg" />
            </video>
        </div>
        {props.children}
        <div>
            <div className="whodis">
                <span>low effort app by a</span>{" "}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://instagram.com/ambrkhs"
                >
                    raver
                </a>{" "}
                |{" "}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/watch?v=psSl6GHsBPU"
                >
                    video credits
                </a>
            </div>
        </div>
    </>
);

const Container = (props: Children) => (
    <div className="container">
        <div className="background"></div>
        <div className="content">{props.children}</div>
        <div className="whodis">
            <span>low effort app by a</span>{" "}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://instagram.com/ambrkhs"
            >
                raver
            </a>
        </div>
    </div>
);

export const Default = () => {
    const [state, setState] = useState(initialState);
    return state.step === 1 ? (
        <QuestionOne update={wizard => setState({ ...state, step: wizard })} />
    ) : state.step === 2 ? (
        <QuestionTwo update={wizard => setState({ ...state, step: wizard })} />
    ) : state.step === 3 ? (
        <QuestionThree
            update={wizard => setState({ ...state, step: wizard })}
        />
    ) : state.step === 4 ? (
        <QuestionFour update={wizard => setState({ ...state, step: wizard })} />
    ) : state.step === "inkakkenIsBijpakken" ? (
        <InKakkenIsBijPakken
            update={wizard => setState({ ...state, step: wizard })}
        />
    ) : state.step === "singleCorona" ? (
        <SingleCorona update={wizard => setState({ ...state, step: wizard })} />
    ) : state.step === "allCorona" ? (
        <EveryoneCorona
            update={wizard => setState({ ...state, step: wizard })}
        />
    ) : state.step === "friendsCorona" ? (
        <FriendsCorona
            update={wizard => setState({ ...state, step: wizard })}
        />
    ) : state.step === "noCorona" ? (
        <NoCorona update={wizard => setState({ ...state, step: wizard })} />
    ) : null;
};
